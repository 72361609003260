import React, { useEffect } from 'react'
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import db from '../../../firebase'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../../../layout/dark-default"
import SectionMastHead from "../../../components/organism/product/section-masthead"
import SectionSlider from "../../../components/organism/product/section-slider"
import SectionProduct from "../../../components/organism/product/section-product"
import SectionProducts from "../../../components/organism/product/section-products2"

const ID2 = ({ id2 }) => {
  const [subProductData, setSubProductData] = useState([]);
  const [subcategoryImageUrl, setSubcategoryImageUrl] = useState([]);
  const [subcategoryCartImageUrl, setSubcategoryCartImageUrl] = useState([]);
  const storage = getStorage();
  let SlugFilter;


  useEffect(() => {
    db.collection('Product').onSnapshot(snapshot => {
      setSubProductData(snapshot.docs.map(doc => ({
        ID: doc.id,
        Description: doc.data().Description,
        canonical_url: doc.data().canonical_url,
        categories: doc.data().categories,
        meta_desc: doc.data().meta_desc,
        meta_title: doc.data().meta_title,
        parent_productslug: doc.data().parent_productslug,
        slug: doc.data().slug,
        subcategory_product_banner: doc.data().subcategory_product_banner,
        subcategory_product_card_img: doc.data().subcategory_product_card_img,
        subcategory_product_card_title: doc.data().subcategory_product_card_title,
        subcategory_product_img: doc.data().subcategory_product_img,
        subcategory_product_link: doc.data().subcategory_product_link,
        product_slider_img: doc.data().product_slider_img,
        related_subproducts: doc.data().related_subproducts,
        title: doc.data().title
      })))
    })
  }, [])

  SlugFilter = subProductData.filter(p => p.slug == id2);


  useEffect(() => {
    SlugFilter[0] && SlugFilter[0].subcategory_product_img.map(image => {
        if (typeof image === "string") {
            const imagaRef = ref(storage, image);
            setTimeout(() => {
                getDownloadURL(imagaRef).then(url => {
                  setSubcategoryImageUrl((prev) => [...prev, url]);
                })
            },);
        }
    })
}, [subProductData])


useEffect(() => {
  SlugFilter[0] && SlugFilter[0].subcategory_product_card_img.map(image => {
      if (typeof image === "string") {
          const imagaRef = ref(storage, image);
          setTimeout(() => {
              getDownloadURL(imagaRef).then(url => {
                setSubcategoryCartImageUrl((prev) => [...prev, url]);
              })
          },);
      }
  })
}, [subProductData])

console.log(subProductData, "subProductData");



  // Product
  // Description
  // canonical_url
  // categories
  // meta_desc
  // meta_title
  // product_slider_img
  // related_subproducts
  // slug
  // title



  //  -- Subproduct
  // Description
  // canonical_url
  // categories
  // meta_desc
  // meta_title
  // parent_productslug
  // slug
  // subcategory_product_banner
  // subcategory_product_card_img
  // subcategory_product_card_title
  // subcategory_product_img
  // subcategory_product_link
  // title


  return (
    <>
      <Helmet>
        {SlugFilter[0] ? <title>{SlugFilter[0] && `${SlugFilter[0].title} - Glastone Mosaic`}</title> : <title>{SlugFilter[0] && SlugFilter[0].meta_title}</title>}
        {SlugFilter[0] ? <meta name="description" content={SlugFilter[0].meta_desc} /> : null}
        {SlugFilter[0] ? <meta name="keywords" content={SlugFilter[0].metakeywords} /> : null}
        <link rel="canonical" href={SlugFilter[0] && SlugFilter[0].canonical_url} />
      </Helmet>

      <Layout>
        {subProductData && <SectionMastHead title={SlugFilter[0] && SlugFilter[0].title.replace("&nbsp;", " ")} desc={SlugFilter[0] && SlugFilter[0].Description} subprod={SlugFilter[0] && SlugFilter[0].sliderImg && SlugFilter[0].subCategory_product_data || SlugFilter[0] && SlugFilter[0].product_slider_img || SlugFilter[0] && SlugFilter[0].product_slider_img ? false : "section_sub_pb"} />}

        {/* Slider image is Not available */}
        {/* {subProductData ? <SectionSlider imgUrl={sliderImageUrl && sliderImageUrl} /> : null} */}

        {/* Not available sub-product data */}
        {/* {product[2].related_subproducts ? product[2].related_subproducts.map((data, index) => (
                    <SectionProduct data={data.subcategory_product_data} parentslug={product.slug} key={product.slug + index} />
        )) : null} */}

        {subProductData ? <SectionProducts technical={false} products={subcategoryImageUrl} /> : null}
        {/* {subProductData ? <SectionProducts technical products={subcategoryCartImageUrl} /> : null} */}
      </Layout>
    </>
  )
}

export default ID2